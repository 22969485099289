import React from 'react';
import logo from './logo.svg';
import './App.css';
import Amplify, {API, Auth, selectInput} from 'aws-amplify';
import { withAuthenticator, AmplifySignOut} from '@aws-amplify/ui-react';

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_ZWEBfiX60",
    userPoolWebClientId: "3lfdrihescrjr3d5q1h1a4h54j",
  },

  API: {
    endpoints: [
      {
        name: "demo",
        endpoint:
          "https://w5pt6uy935.execute-api.us-east-1.amazonaws.com/PROD",
          custom_header:async() => {
            // return {Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`}
            return {Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`}

          }
      },
    ],
  },
});

function App (){
  const [input, setInput] = React.useState("");
  const [apiData, setApiData] = React.useState("");
  const handleClick = async (e) => {
    let t=e.input
    if (t.length < 1) {
      setApiData("You need to type an address")
      return;
    }
    let qStr="/query?addressString="+e.input
    const data = await API.get("demo", qStr, {
    // const data = await API.get("demo", "/query?addressString=4155 stonecliffe Dr, monroeville PA", {
      // headers: {
      //   //🚨 don't embed api keys in real life!!
      //   "x-api-key": "grC9ZygLZuaek3hS8Uh6I9rtC5IgYvwd36EAjaba",
      // },
    });
    setApiData(data.body);
  };

  return (
    <div className="App">
      <AmplifySignOut />
      <header className="App-header">
        <p><h1>PA HAF Spatial Query</h1></p>
        <div style={{ width: '100%' }} >
          <div>Please type the address in the following box</div>
          <input style={{ width: '70%' }} placeholder='4143 Stonecliffe Dr., Monroeville, PA' 
            type="text" value={input} onChange={(e) => setInput(e.target.value)}>
          </input>
        </div>
        <br/>
        <div>
          <button onClick={()=>handleClick({input})}>Submit</button>
        </div>
        <br/>
        <p> {apiData}</p>
  
      </header>
    </div>
  );
}

export default withAuthenticator(App);
